<template>
  <v-snackbar :color="snackbar.color" v-model="snackbar.show" :top="true" right>
    <!--:right="true"-->

    <v-icon white>{{ snackbar.icon }}</v-icon>

    <span v-if="snackbar.error">
      {{ snackbar.code }} Fix following Error(s)

      <ul v-for="(error, index) in snackbar.message" :key="index">
        <li>{{ index + 1 }}-{{ error }}</li>
      </ul>
    </span>
    <span v-else>{{ snackbar.message }}</span>
    <v-btn text color="white" @click.native="snackbar.show = false">
      CLOSE
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  name: "SnackBar",
  data() {
    return {
      snackbar: {
        show: false,
        message: "",
      },
    };
  },

  methods: {
    getSnackBar(snackbar) {
      if (snackbar.message !== "") {
        this.snackbar = snackbar;
      }
    },
  },

  created() {},

  computed: {
    snackBars: {
      get() {
        return this.$store.getters.getSnackBar;
      },

      set(value) {
        this.getSnackBar({ ...value });
      },
    },
  },

  watch: {
    snackBars: {
      handler() {
        this.getSnackBar(this.snackBars);
      },
      update() {
        this.getSnackBar(this.snackBars);
      },
      deep: true,
    },
  },
};
</script>
